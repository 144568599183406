import styled from 'styled-components';

export const StyledDivider = styled.hr`
  && {
    background-color: #ed3e3b;
    border: none;
    height: 4px;
    margin: 32px 0;
    width: 192px;
  }
`;
