module.exports = {
  colors: {
    primary: '#242323',
    secondary: '#545454',
    tertiary: '#ED3E3B',
    text: '#242323',
    background: '#ffffff',
  },
  breakpoints: {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  fonts: {
    primary: 'Lato, sans-serif',
    secondary: 'Playfair Display, serif',
  },
  borderRadius: '16px',
  pageWidth: '1000px',
  headerHeight: '100px',
  footerHeight: '125px',
};
