module.exports = {
  author: '@nicolemamay',
  doUseSplashScreen: true,
  icons: {
    skills: [
      {
        name: 'javascript',
        subtitle: 'JavaScript',
      },
      {
        name: 'react',
        subtitle: 'React',
      },
      {
        name: 'redux',
        subtitle: 'Redux',
      },
      {
        name: 'storybook',
        subtitle: 'Storybook',
      },
      {
        name: 'contentful',
        subtitle: 'Contentful',
      },
      {
        name: 'jest',
        subtitle: 'Jest',
      },
    ],
    learning: [
      {
        name: 'typescript',
        subtitle: 'TypeScript',
      },
    ],
  },
  navLinks: {
    menu: [
      // {
      //     id: 'articles',
      //     name: "Articles",
      //     url: "/#articles",
      // },
      {
        id: 'aboutMe',
        name: 'About Me',
        url: '/#about',
      },
      {
        id: 'contact',
        name: 'Contact',
        url: '/#contact',
      },
      // {
      //     id: 'projects',
      //     name: "Projects",
      //     url: "/#projects",
      // },
    ],
  },
  seoTitleSuffix: 'Nicole Mamay', // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  siteDescription:
    'Nicole Mamay is a software engineer, specialized in front end web development.',
  siteIcon: `src/images/favicon.png`,
  siteLanguage: 'en_US',
  siteShortTitle: 'nm.', // Used as logo text in header, footer, and splash screen
  siteTitle: 'Nicole Mamay | Software Engineer',
  siteUrl: 'https://nicolemamay.com',

  // mediumRssFeed: "",
  // shownArticles: 3,

  socialMedia: [
    {
      id: 'linkedIn',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/nicole-mamay/',
    },
    // {
    //   id: 'github',
    //   name: 'Github',
    //   url: '',
    // },
    // {
    //   name: "Medium",
    //   url: ""
    // },
  ],
};
