import styled from 'styled-components';

export const StyledIcon = styled.div`
  && {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 10px;
    min-width: 135px;
    width: 30%;
  }
`;
